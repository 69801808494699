/**
 * Copyright Highway9 Networks Inc.
 */
import {
  AccountCircleRounded,
  ExitToAppRounded,
  PeopleAltOutlined,
  ShoppingCartRounded,
  GroupWorkRounded,
  PauseCircleOutlineRounded,
  EqualizerRounded,
  TuneRounded,
  LocationOnRounded,
  SettingsInputAntennaRounded,
  DevicesRounded,
  DeviceHub,
  Edit,
  DeleteRounded,
  CheckCircleOutlineRounded,
  TimerOutlined,
  ErrorOutlineRounded,
  AccessTimeOutlined,
  GetApp,
  InfoTwoTone,
  CodeOutlined,
  Settings,
  CancelRounded,
  CheckCircle,
  Apartment,
  MenuBookRounded,
  CloudDownloadRounded,
  CloudUploadRounded,
  SimCard,
  FiberManualRecord,
  MoveDown,
  ArrowRight,
  ArrowDropDown,
  ReportProblemTwoTone,
  RemoveCircleOutline,
  Delete,
  ContentPasteGo,
  CellTower,
  Memory,
  Tag,
  TurnedIn,
  Info,
  AddCircle,
  Visibility,
  VisibilityOff,
  HandshakeTwoTone,
  DataObject,
  Handshake,
  Tune,
  LockOutlined,
  AccountTree,
  DeveloperMode,
  Security,
  SettingsSuggest
} from "@mui/icons-material";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MenuIcon from "@mui/icons-material/Menu";
import { grey, red, amber, lightBlue, teal, blue, orange } from "@mui/material/colors";
import { CircularProgress, SvgIconProps } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Publish from "@mui/icons-material/Publish";
import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import GroupIcon from "@mui/icons-material/Group";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import PageviewIcon from "@mui/icons-material/Pageview";
import TimelineIcon from "@mui/icons-material/Timeline";
import ViewAgendaOutlinedIcon from "@mui/icons-material/ViewAgendaOutlined";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Crop75Icon from "@mui/icons-material/Crop75";
import { StyledBadge1 } from "./StyledBadge";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import { asset } from "../assets";
import ExtensionIcon from "@mui/icons-material/Extension";
import SettingsIcon from "@mui/icons-material/Settings";
import InventoryIcon from "@mui/icons-material/Inventory";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import SaveIcon from "@mui/icons-material/Save";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import SubtitlesOffIcon from "@mui/icons-material/SubtitlesOff";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ArticleIcon from '@mui/icons-material/Article';
import KeyIcon from '@mui/icons-material/Key';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';

const style = {
  marginBottom: 2,
};

export const Images = {
  H9NLogo: "images/highway9networks.png",
  loginImg: "images/highway9_background.png",
  GCP: "images/GCP.png",
  AWS: "images/AWS.png",
  AZURE: "images/AZURE.png",
  onPrem: "images/OnPrem.svg.jpg",
  radio: "images/celltower.png",
  radioLocation: "images/celltower_red.png",
  GAR: "images/GAR.png",
  GOA: "images/GOA.png",
  GO: "images/GO.png",
  AG: "images/AG.png",
  AO: "images/AO.png",
  GOAR: "images/GOAR.png",
  ARO: "images/ARO.png",
  GOR: "images/GOR.png",
  AR: "images/AR.png",
  RO: "images/RO.png",
  GR: "images/GR.png",
  wentWrong: "images/wentWrong.png",
  highway9Logo: asset.highway9Logo
};

export const Icons = {
  deviceInfo : <PermDeviceInformationIcon />,
  APN_DNN: <AccountTree style={style} />,
  policies: <ViewInArIcon titleAccess="Policies" />,
  simulators: <DeveloperMode titleAccess="Simulators" />,
  VMZ: <Security titleAccess="" />,
  systemSettingsIcon: <SettingsSuggest titleAccess="Settings" />,

  key : <KeyIcon />,
  tenant: <Apartment />,
  partner: <Handshake />,
  orderManagement: <ShoppingCartRounded />,
  serviceInstance: <GroupWorkRounded style={{}} />,
  pausedServiceInstance: <PauseCircleOutlineRounded />,
  userIcon: ({size = 20}) => <AccountCircleRounded style={{ color: "616161", ...style , fontSize : size }} />,
  serviceExtension : (props : SvgIconProps) => <ExtensionIcon {...props} />,
  logoutIcon: <ExitToAppRounded style={{ width: 20 }} />,
  chevronLeftIcon: <ChevronLeftIcon />,
  chevronRightIcon: <ChevronRightIcon />,
  userManagement: <PeopleAltOutlined />,
  cloudDashboard: <EqualizerRounded />,
  settings: <TuneRounded />,
  inboxIcon: <InboxIcon />,
  menuIcon: <MenuIcon />,
  installationSteps: <MoveDown />,
  edge: <DeviceHub />,
  location: <LocationOnRounded />,
  radios: <SettingsInputAntennaRounded />,
  subscribers: <DevicesRounded sx={{ width: 32 }} />,
  subscribersSmall: <DevicesRounded sx={{ width: 16 }} />,
  edit: <Edit style={{ width: 20 }} />,
  metrics: <TimelineIcon />,
  deleteRounded: <DeleteRounded style={{ paddingTop: 3 }} />,
  view: <PageviewIcon />,
  login: <LoginIcon />,
  logout: <LogoutIcon fontSize="small" />,
  add: <AddCircle />,
  inProgress: <CircularProgress size={25} thickness={5} />,
  registered: <CheckCircleOutlineRounded style={{ color: "#026950" }} />,
  accessTimeIcon: <AccessTimeOutlined fontSize="small" style={{ color: grey[500] }} />,
  accessTimeIconForTable: <AccessTimeOutlined fontSize="small" style={{ color: grey[500] }} />,
  acknowledged: <TimerOutlined style={{ color: grey[500] }} />,
  failed: <ErrorOutlineRounded style={{ color: red[500] }} />,
  users: <GroupIcon />,
  user: <PersonIcon />,
  logs: <MenuBookRounded />,
  SAML: <LockIcon />,
  email: <EmailIcon />,
  role: <SupervisorAccountIcon />,
  tenantAdminRole: <SupervisedUserCircleIcon />,
  operatorRole: <ManageAccountsIcon />,
  copy: <CopyIcon />,
  upload: <Publish />,
  download: <GetApp />,
  info: <InfoTwoTone color="primary" />,
  infoWarning: <ReportProblemTwoTone style={{ color: "#FFCD38" }} />,
  password: <VpnKeyIcon />,
  code: <CodeOutlined />,
  vertDots: <MoreVertIcon />,
  configuration: <Settings />,
  cancel: <CancelRounded style={{ color: red[500] }} />,
  active: <CheckCircle style={{ color: "#026950" }} />,
  inactive: <CheckCircle style={{ color: grey[500] }} />,
  operator_role: <ManageAccountsIcon />,
  tenant_admin_role: <SupervisedUserCircleIcon />,
  other_roles: <AssignmentIndIcon />,
  export: <CloudDownloadRounded />,
  import: <CloudUploadRounded />,
  IMSIBlock: <ViewAgendaOutlinedIcon />,
  simcard: <SimCard style={{ ...style, color: "#026950" }} />,
  esimcard: (
    <StyledBadge1 badgeContent={"e"} color="secondary">
      <SimCard style={{ ...style, color: "#026950" }} />
    </StyledBadge1>
  ),
  simvendor: <ContactPageIcon style={{ ...style, color: "#026950" }} />,
  filedownload: <FileDownloadIcon />,
  imsirow: <Crop75Icon />,
  visibility: <Visibility />,
  visibilityOff: <VisibilityOff />,
  resync: <CloudSyncIcon />,
  greenCircle: <FiberManualRecord style={{ color: "#03695F" }} />,
  greyCircle: <FiberManualRecord style={{ color: grey[500] }} />,
  amberCircle: <FiberManualRecord style={{ color: amber[500] }} />,
  redCircle: <FiberManualRecord style={{ color: red[500] }} />,
  radio: <CellTower />,
  radioSmall: <img src={Images.radio} alt="Radio" height="16px" />,
  unknownIcon: <ExtensionIcon color="disabled" />,
  operation: <SettingsIcon />,
  expand: <ArrowRight style={{ color: grey[500] }} />,
  collapse: <ArrowDropDown style={{ color: grey[500] }} />,
  inventory: <InventoryIcon />,
  revoke: <RemoveCircleOutline />,
  hardwareInventory: <Memory />,
  addAbove: <AddCircle style={{ color: lightBlue[500] }} />,
  addBelow: <AddCircle style={{ color: teal[500] }} />,
  delete: <Delete />,
  assignHardware: <ContentPasteGo />,
  cellTowerGreen: <CellTower style={{ color: "#03695F" }} />,
  cellTowerRed: <CellTower style={{ color: red[500] }} />,
  cellTowerOrange: <CellTower style={{ color: orange[500] }} />,
  cellTowerBlue: <CellTower style={{ color: blue[500] }} />,
  cellTowerGrey: <CellTower style={{ color: grey[500] }} />,
  application: <ViewInArIcon />,
  tag: <TurnedIn style={{ color: "#03695F" }} />,
  simManagement: <SimCard />,
  ECIManagement: <Tag />,
  LoaderIcon: <SaveIcon />,
  inService: <SubtitlesIcon style={{ color: "#03695F" }} />,
  underMaintenance: <SubtitlesOffIcon style={{ color: red[500] }} />,
  green: <FiberManualRecord style={{ color: "#07695F" }} />,
  red: <ErrorIcon style={{ color: red[500] }} />,
  ErrorGrey:<ErrorIcon style={{ color: grey[500] }} />,
  grey: <FiberManualRecord style={{ color: grey[500] }} />,
  infoIcon: <Info />,
  closeIcon: <CloseIcon />,
  orange: <FiberManualRecord style={{ color: orange[500] }} />,
  handshake: <HandshakeTwoTone />,
  paid: <PaidOutlinedIcon titleAccess="Paid" />,
  partnerRadioAgent: <ConnectWithoutContactIcon />,
  summary: <DataObject titleAccess="Summary" />,
  Administration: <Tune titleAccess="Administration" />,
  documentIcon: <ArticleIcon />,
  upgradeWarning:<LockOutlined/>,

};
